
import { defineComponent, inject, PropType, Ref, ref } from 'vue'

import { required, minValue } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import { ProductVariationRepositoryKey } from '@/data/injectables'
import {
  CreateProductVariationParams,
  ProductVariationForm,
} from '@/data/product-variation/types'
import { UserLogData } from '@/data/auth/types'

import BlockUI from '@/components/BlockUI.vue'
import FormField from '@/components/FormField.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import UserLog from '@/components/UserLog.vue'

export default defineComponent({
  components: { NavBar, FormField, BlockUI, UserLog },
  props: {
    productId: {
      type: Number as PropType<number>,
      required: true,
    },
    productVariationId: {
      type: Number as PropType<number>,
      default: null,
    },
  },
  setup: (props) => {
    const productVariationRepository = inject(ProductVariationRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)

    const form = ref<ProductVariationForm>({
      productId: props.productId,
      size: undefined,
      price: undefined,
      unavailable: false,
    })

    const v$ = useFormValidation(form)

    return {
      form,
      v$,
      ...toast,
      productVariationRepository,
      handleError,
    }
  },
  data() {
    return {
      saving: false,
      deleting: false,
      loading: false,
      userLog: {} as UserLogData,
      submitted: false,
    }
  },
  computed: {
    editing(): boolean {
      return !!this.productVariationId
    },
    title(): string {
      return this.editing
        ? 'Editar variação de produto'
        : 'Criar variação de produto'
    },
    busy(): boolean {
      return this.loading || this.saving || this.deleting
    },
    price: {
      get(): number {
        return Number(this.form.price || 0)
      },
      set(value: number): void {
        this.form.price = value.toString()
      },
    },
  },
  created() {
    if (this.editing) {
      this.loadFormAndUserLog()
    }
  },
  methods: {
    async handleSave(): Promise<void> {
      this.submitted = true
      if (this.v$.$invalid) return

      this.saving = true
      if (this.editing) {
        await this.updateProductVariation()
      } else {
        await this.createProductVariation()
      }
      this.saving = false
    },
    async handleDelete(): Promise<void> {
      this.$confirm.require({
        message: 'Confirma a deleção da variação de produto?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.deleteProductVariation()
        },
      })
    },
    async updateProductVariation(): Promise<void> {
      try {
        await this.productVariationRepository?.updateProductVariation(
          this.productVariationId,
          this.form
        )
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Variação editada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async createProductVariation(): Promise<void> {
      try {
        await this.productVariationRepository?.createProductVariation(
          this.form as CreateProductVariationParams
        )
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Variação criada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async deleteProductVariation(): Promise<void> {
      try {
        this.deleting = true
        await this.productVariationRepository?.deleteProductVariation(
          this.productVariationId
        )
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Variação deletada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.deleting = false
      }
    },
    async loadFormAndUserLog(): Promise<void> {
      try {
        this.loading = true
        const { createdBy, modifiedBy, ...form } =
          (await this.productVariationRepository?.getProductVariation(
            this.productVariationId
          )) || {}
        this.form = form
        this.userLog = {
          createdBy,
          modifiedBy,
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
  },
})

function useFormValidation(form: Ref<ProductVariationForm>) {
  const validationState = {
    form,
  }

  const validationRules = {
    form: {
      size: {
        required,
      },
      price: {
        required,
        minValue: minValue(0),
      },
    },
  }

  return useVuelidate(validationRules, validationState)
}
